import './common';
import '../css/app.scss';

const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

var Wood = {
    init: () => {
        iCheck.init();
        Wood.setup_effects();
        $('[data-toggle="popover"]').popover();
        $('.light_gallery').lightGallery({
            selector: 'div' 
        });
        $('.icon_burger, #icon_fermer').click((e) => {
            e.preventDefault();
            $('#main_menu').slideToggle();
        });
    },
    setup_effects: () => {
        $('#slider_accueil').slick({
            dots: true,
            rows: 0,
            appendArrows: $('.navigation_slider'),
            appendDots: $('.navigation_slider'),
            prevArrow : '<a href="#" title="" class="left"><i class="fa fa-angle-left"></i></a>',
            nextArrow : '<a href="#" title="" class="right"><i class="fa fa-angle-right"></i></a>'
        });
        $('.slider_responsive').slick({
            dots: true,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            appendArrows: $('.navigation_slider'),
            appendDots: $('.navigation_slider'),
            prevArrow : '<a href="#" title="" class="left"><i class="fa fa-angle-left"></i></a>',
            nextArrow : '<a href="#" title="" class="right"><i class="fa fa-angle-right"></i></a>',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
        $('.slider_responsive2').slick({
            dots: true,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            appendArrows: $('.navigation_slider2'),
            appendDots: $('.navigation_slider2'),
            prevArrow : '<a href="#" title="" class="left"><i class="fa fa-angle-left"></i></a>',
            nextArrow : '<a href="#" title="" class="right"><i class="fa fa-angle-right"></i></a>',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
        $('.slider_dots').slick({
            dots: true,
            rows: 0,
            infinite: true,
            speed: 300,
            appendArrows: $('.navigation_slider'),
            appendDots: $('.navigation_slider'),
            prevArrow : '<a href="#" title="" class="left"><i class="fa fa-angle-left"></i></a>',
            nextArrow : '<a href="#" title="" class="right"><i class="fa fa-angle-right"></i></a>'
        });
        $('.slider_base').slick({
            dots: false,
            rows: 0,
            appendArrows: $('.navigation_slider_base'),
            prevArrow : '<a href="#" title="" class="left"><i class="fa fa-angle-left"></i></a>',
            nextArrow : '<a href="#" title="" class="right"><i class="fa fa-angle-right"></i></a>'
        });
        $('.slider_base_dots').slick({
            dots: true,
            rows: 0,
            infinite: false,
            appendDots: $('.navigation_slider'),
            appendArrows: $('.navigation_slider'),
            prevArrow : '<a href="#" title="" class="left"><i class="fa fa-angle-left"></i></a>',
            nextArrow : '<a href="#" title="" class="right"><i class="fa fa-angle-right"></i></a>'
        });
        $('.slider_base_dots2').slick({
            dots: true,
            rows: 0,
            infinite: false,
            appendDots: $('.navigation_slider2'),
            appendArrows: $('.navigation_slider2'),
            prevArrow : '<a href="#" title="" class="left"><i class="fa fa-angle-left"></i></a>',
            nextArrow : '<a href="#" title="" class="right"><i class="fa fa-angle-right"></i></a>'
        });
        $('#lightgallery').lightGallery();
        $("#lightgallery-2").lightGallery();
        $("#lightgallery-3").lightGallery();
        $("#lightgallery-4").lightGallery();
        $("#lightgallery-5").lightGallery();
        $("#lightgallery-6").lightGallery();
        $("#lightgallery-7").lightGallery();
        $("#lightgallery-8").lightGallery();
        $("#lightgallery-9").lightGallery();
        $("#lightgallery-10").lightGallery();
        $('#selector1').lightGallery({
            selector: '.item'
        });
        $('#selector2').lightGallery({
            selector: '.item'
        });
    }
};

Wood.init();